import React, { useState, useEffect } from "react";
import Axios from "axios";
import { useForm } from "react-hook-form";
import Select from 'react-select'

export default function Admin(props) {
  const { register, handleSubmit, setValue, setError, formState: { errors } } = useForm();

  /**
  * List of all users
  */
  const [users, setUsers] = useState([]);

  /**
  * List of all children
  */
  const [children, setChildren] = useState([]);

  /**
  * E-mail of user, before being changed by user
  */
  const [userEmail, setUserEmail] = useState("");

  /**
   * On page load
  */
  useEffect(() => {
    updateUsers();
    updateChildren();
  }, []);

  /**
  * Handles get users request
  */
  const updateUsers = () => {
    Axios.get("/allusers").then((response) => {
      // Transform the API response into the React-Select format
      const formattedUsers = response.data.result.map((user) => ({
        label: "(" + user.id + "): " + user.email, // Display the user's email
        value: user.id,    // Use the user's ID as the value
      }));
      setUsers(formattedUsers); // Store directly in the React-Select format
    });
  };
  

  /**
  * Handles get child request
  */
  const updateChildren = () => {
    Axios.get("/allchildrenadmin").then((response) => {
      const childOptions = response.data.result.map((child) => ({
        label: "(" + child.user_id + "): " + child.name,
        value: child.id,
      }));
      setChildren(childOptions);
    });
  };

  const [formUserValue, setFormUserValue] = useState({
    id: -1
  });

  const [formChildValue, setFormChildValue] = useState({
    id: -1
  });

  const handleUserChange = (selectedOption) => {
    if (!selectedOption || selectedOption.value <= 0) {
      setValue("email", "");
      setFormUserValue({ id: -1 });
      return;
    }
  
    setFormUserValue({ id: -1 });
  
    // Fetch the selected user's details
    Axios.get("/user", {
      params: { id: selectedOption.value },
    }).then((response) => {
      const user = response.data.result[0];
      setFormUserValue({ id: user.id });
      setValue("email", user.email);
      setValue("credits", user.credits);
      setUserEmail(user.email);
    });
  };
   

  const userOnSubmit = (e) => {
    Axios.post("/edituser", {
      id: formUserValue.id,
      email: e.email,
      credits: e.credits,
    }).then((response) => {
      setFormUserValue({
        id: -1,
      });

      setUserEmail("");
      updateUsers();
    });
  }

  const userOnSubmitDelete = (e) => {
    Axios.post("/deleteuser", {
      id: formUserValue.id,
    }).then((response) => {
      setFormUserValue({
        id: -1,
      });

      setValue('email', '');
      setValue('credits', 0);

      setUserEmail("");
      updateUsers();
    });
  }

  const handleChildChange = (selectedOption) => {
    if (!selectedOption || selectedOption.value <= 0) {
      setFormChildValue({ id: -1 });
      return;
    }
  
    setFormChildValue({ id: -1 });
  
    Axios.get("/getchildadmin", {
      params: {
        childid: selectedOption.value,
      },
    }).then((response) => {
      const child = response.data.result[0];
      const [year, month, day] = child.birthday.split("T")[0].split("-");
  
      setFormChildValue({
        id: child.id,
        name: child.name,
        year,
        month,
        day,
        status: child.status,
        reason: child.reason,
      });
  
      setValue("name", child.name);
      setValue("year", year);
      setValue("month", month);
      setValue("day", day);
      setValue("status", child.status);
      setValue("reason", child.reason);
    });
  };
  

  const childOnSubmit = (e) => {
    Axios.post("/updatechildadmin", {
      childid: formChildValue.id,
      name: e.name,
      month: e.month,
      day: e.day,
      year: e.year,
      status: e.status,
      reason: e.reason,
    }).then((response) => {
      setFormChildValue({
        id: -1
      });

      setValue('name', '');
      setValue('month', 1);
      setValue('day', 1);
      setValue('year', 2022);
      setValue('status', 0);
      setValue('reason', '');

      updateChildren();
    });
  }

  const checkEmailExist = (e) => {
    Axios.get("/checkemail", {
      params: {
        email: formUserValue.email,
      }
    }).then((response) => {
      if (response.data.result && userEmail != formUserValue.email) {
        setError('email', { type: 'custom', message: 'E-mail address already taken!' });
      } else {
        userOnSubmit(e);
      }
    });
  }

  const checkUserForm = (e) => {
    checkEmailExist(e);
  }

  /**
   * Array of years to be used for childens birthdays
  */
  const years = [];

  // Creates years for child birthdays
  for (let i = new Date().getFullYear(); i >= 1900; i--) {
    years.push(<option value={i} key={i}>{i}</option>);
  }

  if (props.loginStatus) {
    return (
      <div>
        <h1>Admin Panel</h1>

        <form method="post" onSubmit={handleSubmit(checkUserForm)}>
          <div className="input-box">
            <b>Edit User:</b>
            <br />


            <Select
              name="editUser"
              onChange={handleUserChange} // Pass selected option object
              value={users.find((option) => option.value === formUserValue.id)} // Match selected value
              options={users} // Directly use the `users` state
              placeholder="Please select a user..."
            />

            {formUserValue.id > -1 ? (
              <span>
                <div className="input-box">
                  Email:<br />
                  <input
                    type="text"
                    {...register("email", {
                      required: "Please enter an e-mail address.",
                      pattern: {
                        value: /\S+@\S+\.\S+/,
                        message: "Enter a valid e-mail address."
                      }
                    })}
                    value={formUserValue.email} />

                  {errors.email && <span role="form-error">{errors.email.message}</span>}
                </div>

                <div className="input-box">
                  Credits:<br />
                  <input
                    type="number"
                    {...register("credits", {
                      required: "Please enter a number of credits.",
                    })}
                    value={formUserValue.credits} />

                  {errors.credits && <span role="form-error">{errors.credits.message}</span>}
                </div>

                <div className="input-box">
                  <button onClick={userOnSubmitDelete}>Delete</button>
                  <button type="submit">Save</button>
                </div>
              </span>
            ) : ''}
          </div>
        </form>

        <form method="post" onSubmit={handleSubmit(childOnSubmit)}>
          <b>Edit Child:</b>
          <br />
          <div className="input-box">
            <Select
              name="editChild"
              onChange={handleChildChange} // Pass selected option object
              value={children.find(option => option.value === formChildValue.id)} // Match selected value
              options={children} // Pass child options
              placeholder="Please select a child..."
            />
          </div>

          {formChildValue.id > -1 ? (
            <span>
              <div className="input-box">
                <b>Child name:</b>
                <br />
                <small><i>This will be the name displayed on the site.</i></small>
                <br />
                <input
                  type="text"
                  {...register("name", {
                    required: "Please enter your child's name.",
                  })}
                  maxLength="50" />

                {errors.name && <span role="form-error">{errors.name.message}</span>}
              </div>

              <div className="input-box">
                <b>Birthday</b>
                <br />
                <select
                  {...register("month")}>
                  <option value="01">January</option>
                  <option value="02">February</option>
                  <option value="03">March</option>
                  <option value="04">April</option>
                  <option value="05">May</option>
                  <option value="06">June</option>
                  <option value="07">July</option>
                  <option value="08">August</option>
                  <option value="09">September</option>
                  <option value="10">October</option>
                  <option value="11">November</option>
                  <option value="12">December</option>
                </select>

                <select
                  {...register("day")}>
                  <option value="01">1</option>
                  <option value="02">2</option>
                  <option value="03">3</option>
                  <option value="04">4</option>
                  <option value="05">5</option>
                  <option value="06">6</option>
                  <option value="07">7</option>
                  <option value="08">8</option>
                  <option value="09">9</option>
                  <option value="10">10</option>
                  <option value="11">11</option>
                  <option value="12">12</option>
                  <option value="13">13</option>
                  <option value="14">14</option>
                  <option value="15">15</option>
                  <option value="16">16</option>
                  <option value="17">17</option>
                  <option value="18">18</option>
                  <option value="19">19</option>
                  <option value="20">20</option>
                  <option value="21">21</option>
                  <option value="22">22</option>
                  <option value="23">23</option>
                  <option value="24">24</option>
                  <option value="25">25</option>
                  <option value="26">26</option>
                  <option value="27">27</option>
                  <option value="28">28</option>
                  <option value="29">29</option>
                  <option value="30">30</option>
                  <option value="31">31</option>
                </select>

                <select name="year"
                  {...register("year")}>
                  {years}
                </select>
              </div>

              <div className="input-box">
                <b>Good or Bad?</b>
                <br />
                <select
                  {...register("status")}>
                  <option value="0">Bad</option>
                  <option value="1">Good</option>
                </select>
              </div>

              <div className="input-box">
                <b>Reason:</b>
                <br />
                <small><i>What is their reason for being good or bad?</i></small>
                <br />
                <textarea
                  rows="5"
                  {...register("reason", {
                    required: "Please enter a reason for why your child is good or bad.",
                  })}
                  defaultValue={formChildValue.reason}>
                </textarea>

                {errors.reason && <span role="form-error">{errors.reason.message}</span>}
              </div>

              <div className="input-box">
                <button type="submit">Save</button>
              </div>
            </span>
          ) : ''}
        </form>
      </div>
    )
  } else {
    return (
      <div>
        <p className="center-content">
          You are unable to view this page.
        </p>
      </div>
    )
  }
}